import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Платформд хэрхэн мэдээ нийтлэх вэ?",
  "description": null,
  "author": "OTC help",
  "category": "news",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Платформд хэрхэн мэдээ нийтлэх вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Платформд админ болон андеррайтер эрхтэй хэрэглэгч `}<strong parentName="li">{`[News]`}</strong>{` хэсэгт мэдээ нийтлэх боломжтой.`}</li>
      <li parentName="ol">{`Мэдээ нийтлэхэд `}<strong parentName="li">{`[News]`}</strong>{` хэсгийн баруун дээд булаан дахь `}<strong parentName="li">{`[Post News]`}</strong>{` товчийг дарж мэдээллээ дараах байдлаар оруулна: - Тухайлсан үнэт цаастай холбоотой мэдээ оруулах бол `}<strong parentName="li">{`[Security (optional)]`}</strong>{` хэсэгт үнэт цаасыг сонгоно. Аль нэг үнэт цаастай холбоогүй мэдээлэл нийтлэх үед энэ хэсгийг хоосон үлдээж болно. - `}<strong parentName="li">{`[Title]`}</strong>{` хэсэгт мэдээний гарчиг - `}<strong parentName="li">{`[Content]`}</strong>{` хэсэгт мэдээний агуулга - `}<strong parentName="li">{`[Categories]`}</strong>{` хэсэгт мэдээний төрлийг оруулна
Мэдээллүүдийг оруулаад `}<strong parentName="li">{`[Publish]`}</strong>{` товчийг дарснаар тухайн мэдээ платформд амжилттай нийтлэгдэх болно.`}</li>
      <li parentName="ol">{`Нийтлэгдсэн мэдээнд өөрчлөлт оруулах бол тухайн мэдээний баруун булан дахь `}<strong parentName="li">{`[Request Update]`}</strong>{` товчийг дарж админд мэдээ засах хүсэлт илгээнэ.`}</li>
      <li parentName="ol">{`Хүсэлтийг админ зөвшөөрсөн тохиолдолд мэдээний баруун буланд `}<strong parentName="li">{`[Update]`}</strong>{` товчийг дарж мэдээг шинэчлэн `}<strong parentName="li">{`[Update]`}</strong>{` дарж нийтэлнэ.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      